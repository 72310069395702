<!--指标库设置  -->
<template>
	<div>
		<div class="child-top">
			<div class="searchArea input-from">
				<el-input class="input-with-select" clearable placeholder="请输入指标名称" style="width: 200px" suffix-icon="iconfont iconsousuo" v-model="form.keyword"></el-input>
				<el-button class="searchBtn" @click="getData" round>搜索</el-button>
			</div>
			<div class="operation-button">
				<el-button @click="addDataLog" class="new_btn" round :disabled="$store.getters.permissionsStr('新增')">
					<span>新增</span>
				</el-button>
				<el-button class="del_btn" round @click="someDels()" :disabled="$store.getters.permissionsStr('删除') || !sels.length">
					<span>删除</span>
				</el-button>
			</div>
		</div>
		<div style="background: #fff">
			<el-table
				:empty-text="form.tableList.length ? '' : '暂无数据'"
				v-loading="tableLoading"
				class="content-test-table"
				:data="!$store.getters.permissionsStr('列表') ? form.tableList : []"
				style="width: 100%"
				@selection-change="selsChange"
			>
				<el-table-column :selectable="checkboxSelect" show-overflow-tooltip type="selection" width="45"></el-table-column>
				<el-table-column label="序号" prop="order_no" show-overflow-tooltip type="index" width="60">
					<template slot-scope="scope">{{ (form.page - 1) * 10 + scope.$index + 1 }}</template>
				</el-table-column>
				<el-table-column label="指标名称" prop="cn_name" show-overflow-tooltip></el-table-column>
				<el-table-column label="指标单位" prop="unit_name" show-overflow-tooltip></el-table-column>
				<el-table-column width="240" label="标准范围(男)" show-overflow-tooltip>
					<template slot-scope="scope">
						<div v-if="scope.row.male_symbol === 1">
							<span><</span>
							{{ scope.row.male_upper_limit == 0 ? '' : scope.row.male_upper_limit }}
						</div>
						<div v-if="scope.row.male_symbol === 2">
							<span><=</span>
							{{ scope.row.male_upper_limit == 0 ? '' : scope.row.male_upper_limit }}
						</div>
						<div v-if="scope.row.male_symbol === 3">
							<span>></span>
							{{ scope.row.male_lower_limit == 0 ? '' : scope.row.male_lower_limit }}
						</div>
						<div v-if="scope.row.male_symbol === 4">
							<span>>=</span>
							{{ scope.row.male_lower_limit == 0 ? '' : scope.row.male_lower_limit }}
						</div>
						<div v-if="scope.row.male_symbol === 5">
							{{ scope.row.male_lower_limit == 0 ? '' : scope.row.male_lower_limit }}
							~
							{{ scope.row.male_upper_limit == 0 ? '' : scope.row.male_upper_limit }}
						</div>
						<div v-if="scope.row.male_symbol === 6">
							{{ scope.row.male_lower_limit == 0 ? '' : scope.row.male_lower_limit }}
							~
							{{ scope.row.male_upper_limit == 0 ? '' : scope.row.male_upper_limit }}
						</div>
						<div v-if="scope.row.male_symbol === 7">
							{{ scope.row.male_lower_limit == 0 ? '' : scope.row.male_lower_limit }}
							~
							{{ scope.row.male_upper_limit == 0 ? '' : scope.row.male_upper_limit }}
						</div>
						<div v-if="scope.row.male_symbol === 8">
							{{ scope.row.male_lower_limit == 0 ? '' : scope.row.male_lower_limit }}
							~
							{{ scope.row.male_upper_limit == 0 ? '' : scope.row.male_upper_limit }}
						</div>
					</template>
				</el-table-column>
				<el-table-column width="240" label="标准范围(女)" show-overflow-tooltip>
					<template slot-scope="scope">
						<div v-if="scope.row.female_symbol === 1">
							<span><</span>
							{{ scope.row.female_upper_limit == 0 ? '' : scope.row.female_upper_limit }}
						</div>
						<div v-if="scope.row.female_symbol === 2">
							<span><=</span>
							{{ scope.row.female_upper_limit == 0 ? '' : scope.row.female_upper_limit }}
						</div>
						<div v-if="scope.row.female_symbol === 3">
							<span>></span>
							{{ scope.row.female_lower_limit == 0 ? '' : scope.row.female_lower_limit }}
						</div>
						<div v-if="scope.row.female_symbol === 4">
							<span>>=</span>
							{{ scope.row.female_lower_limit == 0 ? '' : scope.row.female_lower_limit }}
						</div>
						<div v-if="scope.row.female_symbol === 5">
							{{ scope.row.female_lower_limit == 0 ? '' : scope.row.female_lower_limit }}
							~
							{{ scope.row.female_upper_limit == 0 ? '' : scope.row.female_upper_limit }}
						</div>
						<div v-if="scope.row.female_symbol === 6">
							{{ scope.row.female_lower_limit == 0 ? '' : scope.row.female_lower_limit }}
							~
							{{ scope.row.female_upper_limit == 0 ? '' : scope.row.female_upper_limit }}
						</div>
						<div v-if="scope.row.female_symbol === 7">
							{{ scope.row.female_lower_limit == 0 ? '' : scope.row.female_lower_limit }}
							~
							{{ scope.row.female_upper_limit == 0 ? '' : scope.row.female_upper_limit }}
						</div>
						<div v-if="scope.row.female_symbol === 8">
							{{ scope.row.female_lower_limit == 0 ? '' : scope.row.female_lower_limit }}
							~
							{{ scope.row.female_upper_limit == 0 ? '' : scope.row.female_upper_limit }}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="状态" prop="status" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-switch
							v-show="scope.row.uuid"
							active-color="#0055E9"
							:active-value="1"
							:inactive-value="0"
							:width="35"
							@change="ChangeState(scope.row)"
							inactive-color="#ccc"
							v-model="scope.row.status"
							:disabled="$store.getters.permissionsStr('启/停用')"
						></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作" show-overflow-tooltip width="120">
					<template slot-scope="scope">
						<!-- :disabled="scope.row.edit_flag==1?false:true" -->
						<el-button
							style="color: #0055e9"
							:class="scope.row.edit_flag !== 1 ? 'disColor right20' : 'blueColor right20'"
							@click="postEdit(scope.row)"
							size="medium"
							type="text"
							v-show="scope.row.uuid"
							:disabled="$store.getters.permissionsStr('编辑') || scope.row.edit_flag !== 1"
							>编辑</el-button
						>
						<el-button
							:class="scope.row.edit_flag !== 1 || scope.row.use_num ? 'disColor' : 'redColor'"
							@click="Delete(scope.row)"
							size="medium"
							type="text"
							v-show="scope.row.uuid"
							:disabled="$store.getters.permissionsStr('删除') || scope.row.edit_flag !== 1 || scope.row.use_num ? true : false"
							>删除</el-button
						>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				:current-page.sync="form.page"
				:page-size="form.page_size"
				:total="form.total"
				@current-change="handleCurrentChange"
				class="table-pagination"
				layout="total, jumper,  ->, prev, pager, next"
			></el-pagination>
		</div>
		<!-- 新增编辑弹框 -->
		<div>
			<el-dialog @close="clearForm" :close-on-click-modal="false" :visible.sync="centerDialogVisible" :title="titleMap[dialogStatus]" center style="text-align: center" width="60%">
				<div style="margin-bottom: 40px">
					<el-form :label-position="'left'" label-width="82px">
						<div style="display: flex">
							<el-form-item label="指标名称" style="line-height: 34px" required>
								<el-input auto-complete="off" v-model="addform.addname" placeholder="请输入指标名称" style="width: 188px"></el-input>
							</el-form-item>
							<el-form-item label="指标单位" style="line-height: 34px; margin-left: 60px">
								<el-select clearable placeholder="请选择指标单位" v-model="addform.sellist">
									<el-option v-for="item in releSelectsArr" :key="item.uuid" :label="item.cn_name" :value="item.uuid"></el-option>
								</el-select>
							</el-form-item>
						</div>
						<el-form-item label="指标属性" prop="edit_flag" required>
							<el-radio v-model="radio" :label="1">可编辑</el-radio>
							<el-radio v-model="radio" :label="0">只读</el-radio>
						</el-form-item>
						<el-form-item label="指标类型" prop="value_type" required>
							<el-radio v-model="radio1" :label="1">数字</el-radio>
							<el-radio style="margin-left: 12px" v-model="radio1" :label="2">文本</el-radio>
							<el-radio v-model="radio1" style="margin-left: 12px" :label="3">时间</el-radio>
						</el-form-item>
						<el-form-item class="boyipt" v-if="radio1 == 1" style="display: flex; flex: 1">
							<div style="display: flex; flex-warp: warp">
								<span style="margin: 0 20px 0 0">标准范围(男)</span>
								<el-select @change="val => clearForm2('男', val)" clearable placeholder="请选择关系" v-model="addform.boyVule">
									<el-option v-for="item in boyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
								</el-select>
								<div class="text" v-if="addform.boyVule == 1">
									<el-input v-model="addform.iptVals"></el-input>
								</div>
								<div class="text" v-if="addform.boyVule == 2">
									<el-input v-model="addform.iptVals"></el-input>
								</div>
								<div class="text" v-if="addform.boyVule == 3">
									<el-input v-model="addform.iptVal"></el-input>
								</div>
								<div class="text" v-if="addform.boyVule == 4">
									<el-input v-model="addform.iptVal"></el-input>
								</div>
								<div v-if="addform.boyVule == 5" style="display: flex">
									<div style="display: flex; margin-left: 20px">
										<el-input v-model="addform.iptVal"></el-input>
										<span>至</span>
										<el-input v-model="addform.iptVals"></el-input>
									</div>
								</div>
								<div v-if="addform.boyVule == 6" style="display: flex">
									<div style="display: flex; margin-left: 20px">
										<el-input v-model="addform.iptVal"></el-input>
										<span>至</span>
										<el-input v-model="addform.iptVals"></el-input>
									</div>
								</div>
								<div v-if="addform.boyVule == 7" style="display: flex">
									<div style="display: flex; margin-left: 20px">
										<el-input v-model="addform.iptVal"></el-input>
										<span>至</span>
										<el-input v-model="addform.iptVals"></el-input>
									</div>
								</div>
								<div v-if="addform.boyVule == 8" style="display: flex">
									<div style="display: flex; margin-left: 20px">
										<el-input v-model="addform.iptVal"></el-input>
										<span>至</span>
										<el-input v-model="addform.iptVals"></el-input>
									</div>
								</div>
							</div>
						</el-form-item>
						<el-form-item class="giript" v-if="radio1 == 1">
							<div style="display: flex">
								<span style="margin: 0 20px 0 0">标准范围(女)</span>
								<el-select @change="val => clearForm1('女', val)" clearable placeholder="请选择关系" v-model="addform.girlValue">
									<el-option v-for="item in boyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
								</el-select>
								<div v-if="addform.girlValue == 1" class="text">
									<el-input v-model="addform.girtPrices"></el-input>
								</div>

								<div v-if="addform.girlValue == 2" class="text">
									<el-input v-model="addform.girtPrices"></el-input>
								</div>
								<div v-if="addform.girlValue == 3" class="text">
									<el-input v-model="addform.girtPrice"></el-input>
								</div>
								<div v-if="addform.girlValue == 4" class="text">
									<el-input v-model="addform.girtPrice"></el-input>
								</div>
								<div v-if="addform.girlValue == 5" style="display: flex">
									<div style="display: flex; margin-left: 20px">
										<el-input v-model="addform.girtPrice"></el-input>
										<span>至</span>
										<el-input v-model="addform.girtPrices"></el-input>
									</div>
								</div>
								<div v-if="addform.girlValue == 6" style="display: flex">
									<div style="display: flex; margin-left: 20px">
										<el-input v-model="addform.girtPrice"></el-input>
										<span>至</span>
										<el-input v-model="addform.girtPrices"></el-input>
									</div>
								</div>
								<div v-if="addform.girlValue == 7" style="display: flex">
									<div style="display: flex; margin-left: 20px">
										<el-input v-model="addform.girtPrice"></el-input>
										<span>至</span>
										<el-input v-model="addform.girtPrices"></el-input>
									</div>
								</div>
								<div v-if="addform.girlValue == 8" style="display: flex">
									<div style="display: flex; margin-left: 20px">
										<el-input v-model="addform.girtPrice"></el-input>
										<span>至</span>
										<el-input v-model="addform.girtPrices"></el-input>
									</div>
								</div>
							</div>
						</el-form-item>
					</el-form>
				</div>

				<div class="form-bottom-button" style="margin-bottom: 20px">
					<el-button @click="submitForm" type="primary" v-no-more-click class="save_btn" round>确 定</el-button>
					<el-button @click="centerDialogVisible = false" class="cancel_btn" round>取 消</el-button>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			tableLoading: true, //loading
			titleMap: {
				createde: '新增指标',
				Preview: '编辑指标',
			},
			//新增和编辑弹框标题
			dialogStatus: '',
			centerDialogVisible: false, //关闭弹框
			editMen: '',
			editMenIptVals: '',
			editMenIptVal: '',
			editWomenGirlValue: '',
			editWomenGirtPrices: '',
			editWomenGirtPrice: '',
			form: {
				tableList: [{ uuid: 0 }],
				page: 1, //当前页
				page_size: 10,
				keyword: '', //搜索框
				total: 0, //
			},
			radio: 1,
			radio1: 1,
			sels: [], // 列表选中列
			releSelectsArr: [], //单位数据
			addform: {
				addname: '',
				sellist: '',
				boyVule: '',
				girlValue: '',
				iptVal: '',
				iptVals: '',
				girtPrice: '',
				girtPrices: '',
				uuid: '',
			},

			boyList: [
				{
					id: 1,
					name: '<',
				},
				{
					id: 2,
					name: '<=',
				},
				{
					id: 3,
					name: '>',
				},
				{
					id: 4,
					name: '>=',
				},
				{
					id: 5,
					name: '区间(3段)左开右闭',
				},
				{
					id: 6,
					name: '区间(3段)左闭右开',
				},
				{
					id: 7,
					name: '区间(3段)左开右开',
				},
				{
					id: 8,
					name: '区间(3段)左闭右闭',
				},
			],
		}
	},

	components: {},

	computed: {},

	created() {},
	mounted() {
		this.getData()
		this.releSelect()
	},

	methods: {
		clearForm1(sex, val) {
			let arr = [1, 2, 3, 4, 5, 6, 7, 8]
			if (arr.indexOf(this.editWomenGirlValue) != -1) {
				this.addform.girtPrices = this.addform.girtPrice = ''
			} else {
				if (arr.indexOf(val) != -1) {
					this.addform.girtPrices = this.addform.girtPrice = ''
				}
			}
			if (val == this.editWomenGirlValue) {
				this.addform.girtPrices = this.editWomenGirtPrices
				this.addform.girtPrice = this.editWomenGirtPrice
			}

			if (arr.indexOf(val) == -1 && arr.indexOf(this.editWomenGirlValue) == -1) {
				this.addform.girtPrices = this.editWomenGirtPrices
				this.addform.girtPrice = this.editWomenGirtPrice
			}
			// addform.girtPrices = '';addform.girtPrice = '';
		},
		clearForm2(sex, val) {
			let arr = [1, 2, 3, 4, 5, 6, 7, 8]
			if (arr.indexOf(this.editMen) != -1) {
				this.addform.iptVals = this.addform.iptVal = ''
			} else {
				if (arr.indexOf(val) != -1) {
					this.addform.iptVals = this.addform.iptVal = ''
				}
			}
			if (val == this.editMen) {
				this.addform.iptVals = this.editMenIptVals
				this.addform.iptVal = this.editMenIptVal
			}

			if (arr.indexOf(val) == -1 && arr.indexOf(this.editMen) == -1) {
				this.addform.iptVals = this.editMenIptVals
				this.addform.iptVal = this.editMenIptVal
			}
			// addform.girtPrices = '';addform.girtPrice = '';
		},
		handleCurrentChange(val) {
			this.form.page = val
			this.getData()
		},
		selsChange(sels) {
			this.sels = sels
		},
		//关闭弹窗清空输入框的值
		clearForm() {
			this.addform.boyVule = ''
			this.addform.addname = ''
			this.addform.sellist = ''
			this.addform.iptVal = ''
			this.addform.iptVals = ''
			this.addform.girlValue = ''
			this.addform.girtPrice = ''
			this.addform.girtPrices = ''
			this.radio1 = 1
			this.radio = 1
		},
		//请求列表数据
		getData() {
			let params = {
				cn_name: this.form.keyword,
				page: this.form.page,
				page_size: this.form.page_size,
			}
			let url = '/p/norm/lists'
			this.$axios.post(url, this.$qs(params)).then(res => {
				if (res.data.code == 0) {
					this.form.tableList = res.data.data.lists
					this.form.total = res.data.data.total
					this.tableLoading = false
				}
			})
		},

		//新增
		addDataLog() {
			this.centerDialogVisible = true
			this.dialogStatus = 'createde'
		},
		//禁用selection
		checkboxSelect(row, rowIndex) {
			if (row.edit_flag == 1) {
				return true // 不禁用
			} else {
				return false // 不禁用
			}
		},
		//编辑
		postEdit(data) {
			this.centerDialogVisible = true
			this.dialogStatus = 'Preview'
			this.addform.uuid = data.uuid
			this.addform.addname = data.cn_name
			this.addform.sellist = data.unit_uuid
			this.addform.boyVule = this.parseNumber(data.male_symbol)
			this.addform.girlValue = this.parseNumber(data.female_symbol)
			this.addform.iptVals = this.parseNumber(data.male_upper_limit)
			this.addform.iptVal = this.parseNumber(data.male_lower_limit)
			this.addform.girtPrices = this.parseNumber(data.female_upper_limit)
			this.addform.girtPrice = this.parseNumber(data.female_lower_limit)
			this.radio = data.edit_flag
			this.radio1 = data.value_type

			this.editMen = this.addform.boyVule
			this.editMenIptVals = this.addform.iptVals
			this.editMenIptVal = this.addform.iptVal
			this.editWomenGirlValue = this.addform.girlValue
			this.editWomenGirtPrices = this.addform.girtPrices
			this.editWomenGirtPrice = this.addform.girtPrice
		},
		parseNumber(val) {
			return Number(val) ? Number(val) : ''
		},
		//修改状态
		ChangeState(data) {
			let url = '/p/norm/able'
			let params = {
				uuid: data.uuid,
			}
			let that = this
			this.$axios.post(url, this.$qs(params)).then(res => {
				if (res.data.code == 0) {
					this.$message({
						// message:response.data.message,
						message: res.data.message,
						type: 'success',
					})
				} else {
					this.$message({
						type: 'error',
						message: res.data.message,
					})
				}

				that.getData()
			})
		},
		//获取指标单位
		releSelect() {
			this.$axios.post('/p/unit/getValidUnits').then(res => {
				if (res.data.code == 0) {
					this.releSelectsArr = res.data.data
				}
			})
		},
		//新增保存
		submitForm(data) {
			if (this.dialogStatus == 'createde') {
				let params = {
					cn_name: this.addform.addname,
					edit_flag: this.radio,
					value_type: this.radio1,
					unit_uuid: this.addform.sellist,
					male_symbol: this.addform.boyVule,
					female_symbol: this.addform.girlValue,
					male_upper_limit: this.addform.iptVals,
					male_lower_limit: this.addform.iptVal,
					female_upper_limit: this.addform.girtPrices, //上线
					female_lower_limit: this.addform.girtPrice, //下线
				}
				if (this.radio1 == 2) {
					params.male_symbol = ''
					params.female_symbol = ''
					params.male_upper_limit = ''
					params.male_lower_limit = ''
					params.female_upper_limit = ''
					params.female_lower_limit = ''
				}

				// if (this.addform.iptVals == 0) {
				//   params.male_upper_limit = "";

				//   params.female_lower_limit = "";
				// }
				// if (this.addform.iptVal == 0) {
				// }
				// if (this.addform.girtPrices == 0) {
				//   params.female_upper_limit = "";
				// }
				// if (this.addform.girtPrice) {
				//   params.male_lower_limit = "";
				// }
				let url = '/p/norm/add'
				this.$axios.post(url, this.$qs(params)).then(res => {
					if (res.data.code == 0) {
						this.$message({
							message: res.data.message,
							type: 'success',
						})
						this.centerDialogVisible = false
						this.getData()
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
			} else {
				let params = {
					cn_name: this.addform.addname,
					edit_flag: this.radio,
					value_type: this.radio1,
					unit_uuid: this.addform.sellist,
					male_symbol: this.addform.boyVule,
					female_symbol: this.addform.girlValue,
					male_upper_limit: this.addform.iptVals,
					male_lower_limit: this.addform.iptVal,
					female_upper_limit: this.addform.girtPrices, //上线
					female_lower_limit: this.addform.girtPrice, //下线
					uuid: this.addform.uuid,
				}
				if (this.radio1 == 2 || this.radio1 == 3) {
					params.male_symbol = ''
					params.female_symbol = ''
					params.male_upper_limit = ''
					params.male_lower_limit = ''
					params.female_upper_limit = ''
					params.female_lower_limit = ''
				}
				let url = '/p/norm/update'
				this.$axios.post(url, this.$qs(params)).then(res => {
					if (res.data.code == 0) {
						this.$message({
							// message:response.data.message,
							message: '修改成功',
							type: 'success',
						})
						this.getData()
						this.centerDialogVisible = false
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
			}
		},

		//删除数据
		Delete(data) {
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				closeOnClickModal: false,
				cancelButtonClass: '',
				center: true,
			}).then(res => {
				let params = {
					uuids: data.uuid,
				}
				let url = '/p/norm/del'
				this.$axios.post(url, this.$qs(params)).then(res => {
					if (res.data.code == 0) {
						this.$message({
							message: '删除成功',
							type: 'success',
						})
						this.getData()
					} else {
						this.$message({
							message: res.data.message,
							type: 'error',
						})
					}
				})
			})
		},
		someDels() {
			let that = this
			//删除数据
			if (that.sels.length == 0) {
				that.$message({
					message: '请选择要删除的数据',
					type: 'error',
					duration: 4000,
				})
				return
			}
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				closeOnClickModal: false,
				cancelButtonClass: '',
				center: true,
			}).then(() => {
				let params = []
				let url = '/p/norm/del'

				for (let i = 0; i < this.sels.length; i++) {
					params[i] = this.sels[i].uuid
				}
				let ids = params.join(',')
				let data = { uuids: ids }
				this.$axios.post(url, this.$qs(data)).then(res => {
					if (res.data.code == 0) {
						this.getData()
						this.$message({
							message: res.data.message,
							type: 'success',
						})
					} else {
						this.$message({
							message: res.data.message,
							type: 'error',
						})
					}
				})
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.disColor {
	color: #ccc !important;
}

.text {
	display: inline-block;
	margin-left: 20px;
}

/deep/.el-dialog__title {
	color: #303133;
	font-weight: 700;
}

/deep/.el-input {
	position: relative;
	font-size: 0.72917rem;
	display: inline-block;
	width: 222px;
}

/deep/.el-table th > .cell {
	padding-left: 15px;
}
/deep/.el-pagination__editor.el-input {
	width: 50px;
}

.child-top {
	.input-from {
		.searchBtn {
			margin-top: 10px;
		}
	}
	.operation-button {
		// float: left;
		margin-bottom: 10px;
		margin-top: 10px;
	}
}
.child-top .operation-button .del_btn:hover {
	background: #eb1e44;
	color: #ffffff;
}

.el-pagination {
	padding-bottom: 10px;
}
</style>
